/**
 * Created by Vincenzo on 26/12/2016.
 */
$(document).ready(function () {

    if($('.i-claun').length >0) {
        if (($(window).width() < 992) && ($(window).width() > 767)) {
            var maxHeight = -1;
            $(".thumb-info").each(function () {
                maxHeight = maxHeight < $(this).height() ? $(this).height() : maxHeight;
            });

            $(".thumb-info").each(function () {
                $(this).height(maxHeight);
            });
        }
    }
});